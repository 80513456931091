<template>
  <div>
    <v-btn
      color="transparent"
      small
      @click="backTask"
      elevation="0"
      class="mb-2 btn__back"
    >
      <v-icon small left>mdi-arrow-left</v-icon>
      {{ $t("buttons.back") }}
    </v-btn>
    <h1 class="main-title">
      {{ $t("buttons.taskTransportation") }} № {{ taskId }}
    </h1>
    <div class="task-info">
      <v-row class="flex-lg-row flex-sm-column-reverse mobile">
        <v-col md="12" lg="6">
          <div class="task-col">
            <v-text-field
              v-for="(el, index) in items"
              :key="index"
              :label="el.label"
              :value="el.value"
              disabled
              class="ma-0 task__input"
            />
          </div>
          <v-textarea
            v-for="item in textarea"
            :key="item.label"
            :label="item.label"
            :value="TASK[item.value]"
            background-color="#F1F3F9"
            color="#000"
            rows="3"
            no-resize
            filled
            disabled
            class="task__textarea"
          ></v-textarea>
        </v-col>

        <v-col class="pa-2 d-flex justify-center" md="12" lg="4">
          <div class="qr-code">
            <span v-if="TASK.qr_code <= 0">QR-code</span>
            <img :src="TASK.qr_code" alt="" />
          </div>
        </v-col>
        <v-col md="12" lg="2" class="xs-hidden">
          <v-btn
            class="primary--text"
            color="#F1F3F9"
            elevation="0"
            @click="print"
            >{{ $t("buttons.print") }}</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <h1 class="main-title mb-8 title__empty">
      {{ $t("new.flightsAndWeigh") }}
    </h1>
    <table-empty :list="TRIPTASK" class="table__empty" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import tableEmpty from "@/components/TableEmpty";
export default {
  components: {
    tableEmpty,
  },
  data: (vm) => ({
    taskId: "",
    textarea: [{ label: "", value: "notation" }],
    active: vm.$t("settings.active"),
    inactive: vm.$t("settings.inActive"),
    id: vm.$t("settings.id"),
    creator: vm.$t("settings.creator"),
    order: vm.$t("settings.order"),
    weightbox: vm.$t("table.weightbox"),
    actualTime: vm.$t("settings.actualTime"),
    driver: vm.$t("table.driver"),
    cargo: vm.$t("table.cargo"),
    buyer: vm.$t("table.buyer"),
    status: vm.$t("table.status"),
    vehicle: vm.$t("table.vehicle"),
    capacity: vm.$t("table.capacity"),
  }),
  computed: {
    ...mapGetters(["TASK", "TRIPTASK", "ORDER", "TASKDATA"]),

    items() {
      return this.TASKDATA.map((el) => {
        if (el.value === false) {
          el.value = this.active;
        } else if (el.value === true) el.value = this.inactive;
        if (el.label === "id") {
          el.label = this.id;
        }
        if (el.label === "Creator") {
          el.label = this.creator;
        }
        if (el.label === "Order") {
          el.label = this.order;
        }
        if (el.label === "Weightbox") {
          el.label = this.weightbox;
        }
        if (el.label === "Actual completion time") {
          el.label = this.actualTime;
        }
        if (el.label === "Driver") {
          el.label = this.driver;
        }
        if (el.label === "Cargo") {
          el.label = this.cargo;
        }
        if (el.label === "Buyer") {
          el.label = this.buyer;
        }
        if (el.label === "Status") {
          el.label = this.status;
        }
        if (el.label === "Vehicle") {
          el.label = this.vehicle;
        }
        if (el.label === "Capacity") {
          el.label = this.capacity;
        }
        return el;
      });
    },
  },
  methods: {
    ...mapActions(["getTaskByID", "getTripTask", "getOrderByID"]),
    backTask() {
      this.$router.push({ name: "taskTransport" });
    },

    print() {
      window.print();
    },
  },

  mounted() {
    this.taskId = this.$route.params.userId;
    this.getTaskByID({ id: this.taskId });
    this.getTripTask({ id: this.taskId });
  },
};
</script>

<style lang="scss" >
.task-info {
  margin-bottom: 40px;
}
.qr-code {
  width: 145px;
  height: 145px;
  background: #f1f3f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-col {
  max-height: 420px;
  display: flex;
  flex-direction: column;
  columns: 2;
  flex-wrap: wrap;
  column-gap: 20px;
}
@media (max-width: 900px) {
  .task-col {
    columns: 1;
    max-height: none;
  }
  .mobile {
    flex-direction: column-reverse !important;
  }
  .main-title {
    font-size: 20px;
  }
  .xs-hidden {
    display: none;
  }
}
.tarea.v-input--is-disabled {
  background-color: #f1f3f9;
  padding-left: 20px;
}
.task__input input {
  color: black !important;
}
.task__input label {
  font-weight: 600;
  color: #676767 !important;
}
.task__textarea textarea {
  color: black !important;
}
</style>