<template>
  <div class="table">
    <table>
      <tr>
        <th v-for="item in headers" :key="item.value">{{ item.text }}</th>
      </tr>
      <template v-for="item in list">
        <tr :key="item.id">
          <td class="order-num" colspan="12">{{ item.id }}</td>
        </tr>

        <tr v-for="el in item.weightings" :key="el.dt">
          <td></td>
          <td>
            {{
              new Date(el.dt).toLocaleDateString() +
              ", " +
              new Date(el.dt).toLocaleTimeString()
            }}
          </td>
          <td>{{ el.weighter }}</td>
          <td>{{ el.weight_value }}</td>
          <td>
            <v-btn
              fab
              x-small
              @click.stop="
                openDialog([
                  el.photo_camera1,
                  el.photo_camera2,
                  el.photo_camera3,
                  el.photo_driver,
                  el.photo_license_plate,
                ])
              "
              icon
              center
            >
              <v-icon small> mdi-camera </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <tr v-if="list.length <= 0">
        <td class="nodata" colspan="12">{{ $t("title.nodata") }}</td>
      </tr>
    </table>
    <v-dialog v-model="dialog" max-width="460px">
      <v-card min-height="100vh" class="pa-4">
        <v-card-actions>
          <v-btn class="modal-close" circle icon @click="dialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <h3 class="modal-title">{{ $t("new.photo") }}</h3>
        <div class="block-img">
          <div
            class="block-item"
            v-for="(image, index) in this.images"
            :key="index + '-image'"
          >
            <img :src="image" :alt="image" v-if="image" />
            <p v-else>No photo</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data(vm) {
    return {
      dialog: false,
      images: [],
      headers: [
        { text: vm.$t("table.flight"), value: "", visible: true },
        { text: vm.$t("table.weighingTime"), value: "dt", visible: true },
        { text: vm.$t("table.weighter"), value: "weighter", visible: true },
        {
          text: vm.$t("table.resultingWeight"),
          value: "weight_value",
          visible: true,
        },
        { text: "", value: "actions", visible: true },
      ],
    };
  },
  props: {
    list: {
      type: Array,
      requared: true,
      default: () => [],
    },
  },
  methods: {
    openDialog(images) {
      this.dialog = true;
      this.images = images;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 71, 186, 0.2);
  border-radius: 5px;
}
@media (max-width: 600px) {
  .table {
    overflow-x: auto;
  }
}

th {
  background: #f1f3f9;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.045em;
  color: #000;
  text-align: left;
  &::first-letter {
    text-transform: uppercase;
  }
}
tr th:first-child {
  max-width: 70px;
}
tr:nth-child(odd) {
  background-color: #f8f9fd;
}
tr:nth-child(even) {
  background-color: #f1f3f9;
}
th,
td {
  padding: 15px;
}
tr td:last-child {
  text-align: right;
}
tr td.order-num {
  background-color: #fcfdff;
  text-align: left;
}
.modal-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  color: #111421;
  margin-top: 10px;
}
.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.block-img {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.block-item {
  width: 100%;
  height: 283px;
  background: #f1f3f9;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 600;
    font-size: 30px;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
tr td.nodata {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.045em;
  color: #000;
  background: #fcfdff;
}
</style>